@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500&display=swap");

body {
  overflow-x: hidden !important;
  font-family: "Jost", sans-serif !important;
}

.jost {
  font-family: "Jost", sans-serif;
}

.bg-grad {
  background: linear-gradient(
    to right,
    #8fdcfa 0%,
    #619ff0 50%,
    #6c9feb 50%,
    #3339e9 100%
  );
}

.bg-green {
  background-color: #58d6d9;
}

.gallerybtn {
  margin: 0;
  position: absolute;
  /* top: 1px; */
  z-index: 999999999;
  right: 30px;
  color: white;
  border-radius: 50rem;

  bottom: 35px;
}

h2,
h3 {
  font-size: 30px;
  color: #051322 !important;
  margin: 5px 0;
}

h4,
h5 {
  font-size: 22px;
  color: #58d6d9 !important;
  margin-bottom: 0;
}

h6 {
  font-size: 18px;
}

.bg-blue {
  background-color: #58d6d9 !important;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

.bg-blue:hover {
  background-color: #3533cd !important;
  background-position: left bottom;
}

.accordion-body .selected-item {
  background-color: #013870;
}

.accordion-body .selected-item i {
  color: #58d6d9;
}

.button:hover {
  background-position: left bottom;
}

.space {
  padding: 3rem 0;
}

.bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.icondiv .selected svg,
.icondiv .selected p {
  fill: black !important;
  color: black !important;
  font-weight: 700;
}

.icondiv p {
  cursor: pointer;
}

/* .hero-image {

  height: 50%;

}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
} */

.banner,
.hoteldetail {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  /* background-image: */
  height: 500px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* .hoteldetail {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://images.unsplash.com/photo-1602088435293-14c7dd28b867?auto=format&fit=crop&q=80&w=2073&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
} */
.banner img {
  object-fit: cover;
  height: 500px;
  width: 100%;
}

.mainsearch {
  width: 100%;
  /* background: rgb(19, 23, 29); */
  position: fixed;
  z-index: 999;
  top: 67px;
  padding-top: 10px;
  /* background-color: #0a223d; */
  /* background-image: linear-gradient(145deg, #0a223d 0%, #50018b 100%); */
  background-color: rgb(53, 51, 205);
  background-image: linear-gradient(
    245deg,
    rgb(53, 51, 205) 0%,
    rgb(0, 0, 0) 100%
  );
  padding: 0;
}

@media (max-width: 768px) {
  .increamentbtn {
    display: none;
  }

  .navbar-collapse.collapse.show {
    background-color: rgb(53, 51, 205);
    background-image: linear-gradient(
      245deg,
      rgb(53, 51, 205) 0%,
      rgb(0, 0, 0) 100%
    );
  }
}

p {
  text-align: justify;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.herotext {
  position: absolute;
  top: 45%;
  left: 45%;
}

.herotext h4 {
  font-size: 45px;
  font-weight: 500;
}

.text-grad {
  font-size: 72px;
  background: -webkit-linear-gradient(#051322, #15457c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main img {
  height: 12rem !important;
}

.place .nav-item button {
  background-color: white;
  border-radius: 4px;
  padding-top: 10px;
  padding-right: 18px;
  padding-bottom: 10px;
  padding-left: 18px;
  color: #1a232b;
  font-size: 14px;
  font-weight: 500;
}

.main {
  overflow: hidden;
  margin: 0 auto;
}

.main img {
  transition: 0.5s all ease-in-out;
}

.main:hover img {
  transform: scale(1.2);
}

.card {
  margin-bottom: 20px;
}

.amentityimag {
  height: 50px;
  width: 50px;
}

.selectBox p {
  font-size: 10px;
  margin-bottom: 0;
}

/* .eBDCQk .date {
  border: none;
  font-size: 16px;
  margin-left: 10px;
  position: relative !important;
  bottom: 11px !important;
}

.eBDCQk .fromtodiv2 select {
  padding: 4%;
  width: 100%;
  font-size: 20px !important;
  font-weight: bold;
  border: none;
  position: relative;
  top: -31px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
} */

.eBDCQk {
  height: 108px !important;
  width: 95%;
  margin: auto;
  border: 0.01em solid #d4d0d0;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.selectBox1 p {
  font-size: 12px;
  margin-bottom: 0;
}

.booRrK .selectBox1 p {
  position: relative;
  top: 5px !important;
}

.eBDCQk .date {
  border: none;
  font-size: 16px;
  margin-left: 0px !important;
}

.eBDCQk .fromtodiv2 select {
  padding: 2% !important;
  width: 100%;
  font-size: 22px !important;
  font-weight: bold;
  border: none;
  position: relative;
  top: 0px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.eBDCQk .fromtodiv div {
  border-right: 0.01em solid #d4d0d0;
  height: 100% !important;
}

.eBDCQk .fromtodiv2 div {
  border-right: 0.01em solid #d4d0d0;
  height: 100% !important;
}

.eBDCQk .fromtodiv select {
  padding: 4%;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  border: none;
  position: relative;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.card-body h4 {
  font-size: 18px;
}

.card-body p {
  font-size: 14px;
}

.card-body h6 {
  font-size: 16px;
}

.counter_box span {
  width: 70px;
  height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #58d6d9;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 10px 5px 10px 5px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #58d6d9 !important;
}

.bg-footer {
  background-image: url(./image/footer_bg.jpg) !important;
  background-size: cover !important;
}

.footer-widget .fw-title .title {
  font-size: 22px;
  margin-bottom: 0;
  color: #fff;
  font-weight: 600;
}

.footer a {
  text-decoration: none;
  list-style: none;
  color: #cbccd0;
  font-size: 16px;
}

.footer ul {
  list-style: none;
}

.footer ul li a:hover {
  color: #58d6d9;
  transition: all 0.5s ease-in-out;
}

.footer-widget .fw-link ul li {
  margin-bottom: 10px;
}

.footer-widget .fw-title {
  margin: 25px 0 35px;
}

.footer-content p {
  color: #f2f2f2;
  font-size: 22px;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  margin-bottom: 25px;
}

.footer .socialicons i {
  font-size: 20px;
}

.footer .socialicons li {
  background: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}

.footer .socialicons li i {
  color: #051322;
}

.footer .socialicons li:hover,
.footer .socialicons li:hover i {
  background-color: #58d6d9;
  color: white;
  transition: all ease-in-out 0.1s;
}

#flights .searchbtn {
  margin: auto;
  width: 200px;
  height: 50px;
  color: white;
  font-weight: 600;
  border: none;
  cursor: pointer;
  position: relative;
  top: -27px;
  font-size: 24px;
  border-radius: 25px;
  background: #58d6d9;
  transition: all 0.4s ease;
}

#flights .searchbtn:hover {
  background: #051322;
}

/* .button {
  width: 200px;
  margin: auto;
  button {
  }
} */

.price {
  width: 100px;
  text-align: end;
  background: #ffe03c;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px 0 0 20px;
}

@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .icondiv {
    width: 100% !important;
  }

  #flights .searchbtn {
    top: 6px;
  }

  .banner {
    height: 300px;
  }

  .banner img {
    height: 300px;
  }
}

/* Scrollbar Styles */
.custom-scrollbar,
.offersscroll {
  overflow: auto;
}

.custom-scrollbar::-webkit-scrollbar,
.offersscroll::-webkit-scrollbar {
  width: 2px;
  /* Width of the scrollbar */
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb,
.offersscroll::-webkit-scrollbar-thumb {
  background-color: gray;
  /* Red color for the thumb */
  border-radius: 10px;
  /* Border radius of the thumb */
}

.custom-scrollbar::-webkit-scrollbar-track,
.offersscroll::-webkit-scrollbar-track {
  background-color: white;
  /* Color of the scrollbar track */
}

/* For Firefox */
.custom-scrollbar,
.offersscroll {
  scrollbar-width: thin;
  scrollbar-color: #051322 #f1f1f1;
  /* Red color for the thumb */
}

.offersscroll {
  height: 250px;
}

.tripListWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin-bottom: 12px;
}

.tripListWrapper li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #757575;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.tripListWrapper li {
  width: 50%;
  color: #716454;
  font-size: 14px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 3px;
  position: relative;
  padding-left: 15px;
}

.tripListWrapper li:nth-child(odd) {
  padding-right: 15px;
}

.hotelabout li {
  color: #52afcf;
  font-size: 14px;
}

.cardbtnbook {
  background: #f9f9f9;
  box-shadow: 3px 6px 12px;
  padding: 10px;
  border-radius: 20px;
}

.card {
  height: 100%;
}

.col-md-6 {
  margin-bottom: 10px;
}

.card {
  position: relative;
}

.couponsOuter {
  display: flex;
  width: 100%;
  background: #f2f2f2;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 12px 12px 45px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  height: 100px;
  box-sizing: border-box;
}

.apply {
  position: absolute;
  right: 20px;
  font-size: 14px;
}

.masonry_img {
  height: 250px;
}

@media (max-width: 768px) {
  #flights .col-md-2.col-12,
  #flights .col-md-3.col-12,
  #flights .col-md-2.col-6,
  #flights .col-md-3.col-6 {
    margin-bottom: 10px;
  }

  .masonry_img {
    height: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none !important;
  }

  .input-group.w-50 {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .herotext {
    position: absolute;

    top: 30%;
    left: 30%;
  }

  .packagebox .increamentbtn {
    padding: 10px;
  }
}

.navbar.navbar-expand-lg li a {
  color: white;
}

.navbar-nav .nav-item .nav-link.active {
  color: #58d6d9 !important;
}

.navbar-collapse.collapse.show,
.navbar.navbar-expand-lg.show {
  background-color: white;
}

.my-timeline {
  position: relative;
  max-width: 1200px;
  margin: 100px auto;
}

.my-container {
  padding: 32px 50px;
  position: relative;
  width: 40%;
  opacity: 1;
}

.my-container:nth-child(1) {
  animation-delay: 0s;
}

.my-container:nth-child(2) {
  animation-delay: 1s;
}

.my-container:nth-child(3) {
  animation-delay: 2s;
}

.my-container:nth-child(4) {
  animation-delay: 3s;
}

.my-container:nth-child(5) {
  animation-delay: 4s;
}

.my-container:nth-child(6) {
  animation-delay: 5s;
}

.my-container:nth-child(7) {
  animation-delay: 6s;
}

.my-container:nth-child(8) {
  animation-delay: 7s;
}

.text-box {
  padding: 20px 30px;
  position: relative;
  font-size: 16px;
  color: #1f1f1f;
  font-weight: 400;
  background-color: white;
  border: 2px solid #0fa99f;
  filter: drop-shadow(0px 0px 20px rgba(190, 190, 190, 0.25));
  border-radius: 6px;
}

.left-container {
  left: 0;
}

.right-container {
  left: 50%;
}

.my-container h5 {
  position: absolute;
  color: #000;
  text-align: center;
  font-family: "Lato";
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  right: -162px;
  top: 56px;
}

.right-container h5 {
  left: -995px;
}

.timeline-line {
  position: absolute;
  width: 4px;
  background-color: #0fa99f;
  top: 0;
  left: 50%;
  margin-left: -2px;
  z-index: 1;
  height: 0;
  transition: height 0.5s ease;
}

.timeline-image {
  position: absolute;
  left: 47.6%;
  width: 60px;
  height: auto;
  transition: top 0.5s ease;
  top: 0;
}

.my-timeline::after {
  content: "";
  position: absolute;
  width: 4px;
  background-color: #a3a3a3;
  top: 0;
  left: 50%;
  margin-left: -2px;
  z-index: -1;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .my-timeline {
    margin: 50px auto;
  }

  .my-timeline::after {
    left: 40px;
  }

  .my-container {
    width: 100%;
    padding-left: 80px;
    padding-right: 25px;
  }

  .text-box {
    font-size: 14px;
  }

  .right-container {
    left: 0;
  }
}

.makeFlex {
  display: flex;
}

._Header.imageGalleryTopSection .imageGalleryLeft {
  width: 460px;
  height: 300px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-right: 9px;
}

.slideDetails {
  position: absolute;
  bottom: 10px;
  z-index: 1;
  padding: 5px 13px;
  border-radius: 13px;
  left: 15px;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  font-weight: 700;
}

.imageNameWrapper .galleryImgContent,
.imageGalleryTopSection .imageLoaderContainer > img {
  border-radius: 4px;
  cursor: pointer;
}

.imageLoaderContainer > img.active {
  opacity: 1;
}

.middleBlockLeft {
  width: 225px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageLoaderContainer {
  background-size: cover;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 5vw);
  grid-gap: 0.5rem;
  position: relative;
  top: 110px;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__item--2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;

  /** Alternative Syntax **/
  /* grid-column: 3 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__item--3 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 6;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4;
  grid-row: 1 / span 5; */
}

.gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 6;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 4;  */
  /* grid-row: 3 / span 3; */
}

/* .gallery__item--5 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 6;
  grid-row-end: 9;

 
}

.gallery__item--6 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 9;

  
} */

.navbar {
  border-radius: 0 !important;
}

.productVideo iframe {
  height: 369px;
  object-fit: cover;
  width: 100%;
}

.productVideo .btn-close {
  background-color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 15px;
  height: 25px;
  opacity: 1;
  padding: 4px;
  right: 13px;
  top: 15px;
  transition: all 0.7s;
  width: 25px;
}

.tileclass {
  background-color: #051322;
  border-radius: 10px;
  color: #fff;
  font-size: 24px;
  height: 50px;
  margin-bottom: 0;
  padding: 10px;
  text-align: center;
  width: 300px;
}

#flights {
  position: absolute;
  width: 100%;
  height: 100px;
}

/* .snow {
  position: absolute;
  border-radius: 50%;
  filter: blur(1px);
  background-color: #fff;
  width: 10px;
  height: 10px;
  top: -1000px;
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
  animation: snowfall 7s linear infinite;
}

.snow:before {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: #fff;
  left: 200px;
  top: -150px;
  border-radius: 50%;
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
}

.snow:after {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  background-color: #fff;
  left: 300px;
  top: -250px;
  border-radius: 50%;
  filter: blur(1px);
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
}
.snow1 {
  position: absolute;
  border-radius: 50%;
  filter: blur(2px);
  background-color: #fff;
  width: 9px;
  height: 9px;
  top: -850px;
  left: 123px;
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
  animation-delay: -3s;
  animation: snowfall 9s linear infinite;
}

.snow1:before {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: #fff;
  left: 200px;
  top: -350px;
  border-radius: 50%;
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
}

.snow1:after {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  background-color: #fff;
  left: 300px;
  top: -450px;
  border-radius: 50%;
  filter: blur(1px);
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
}

.snow2 {
  position: absolute;
  border-radius: 50%;
  filter: blur(1.5px);
  background-color: #fff;
  width: 9px;
  height: 9px;
  top: -900px;
  left: -50px;
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
  animation-delay: -4s;
  animation: snowfall 14s linear infinite;
}

.snow2:before {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: #fff;
  left: 200px;
  top: -550px;
  border-radius: 50%;
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
}

.snow2:after {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  background-color: #fff;
  left: 300px;
  top: -650px;
  border-radius: 50%;
  filter: blur(1px);
  box-shadow: 20px 20px 1px #fff, 60px 40px 1px #fff, 160px 135px 1px #fff,
    260px 240px 1px #fff, 360px 340px 1px #fff, 460px 440px 1px #fff,
    560px 340px 1px #fff, 660px 240px 1px #fff, 760px 140px 1px #fff,
    260px 640px 1px #fff, 160px 340px 1px #fff, 260px 440px 1px #fff,
    760px 140px 1px #fff, 860px 40px 1px #fff, 760px 140px 1px #fff,
    1260px 120px 1px #fff, 1460px 10px 1px #fff, 1560px 30px 1px #fff,
    1590px 124px 1px #fff, 1680px 415px 1px #fff, 1720px 335px 1px #fff,
    1000px 212px 1px #fff, 990px 311px 1px #fff, 888px 122px 1px #fff,
    765px 10px 1px #fff, 635px 15px 1px #fff, 554px 111px 1px #fff,
    455px 222px 1px #fff, 125px 105px 1px #fff, 275px 96px 1px #fff,
    245px 40px 1px #fff, 230px 70px 1px #fff, 285px 17px 1px #fff,
    360px 340px 1px #fff, 360px 340px 1px #fff, 360px 340px 1px #fff,
    430px 210px 1px #fff, 523px 23px 1px #fff, 1650px 335px 1px #fff,
    1777px 123px 1px #fff, 1800px 222px 1px #fff, 1360px 140px 1px #fff;
}

@keyframes snowfall {
  100% {
    transform: translateY(1500px);
  }
} */

.navbar-brand img {
  width: 250px;
  height: 60px;
  border-radius: 10px;
}

.navbar-brand {
  padding: 0 !important;
  padding-left: 10px !important;
}

.hotelmodel .btn-close {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  background-color: white;
}

.parackeditor p {
  font-size: 14px;
  margin-bottom: 0;
}

.card-body .dnignt {
  border: 1px solid #16477f;
  border-radius: 4px;
  padding: 2px 4px;
  color: #16477f;
  font-size: 12px;
  font-weight: 700;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* ensure it's above other elements */
  /* background-color: rgb(178 173 172 / 63%); */
  background-color: #eeeeee;
}

.tredingpackagename {
  font-size: 14px;
  width: 150px;
  cursor: pointer;
}

@media (max-width: 1069px) {
  .tredingpackagename {
    font-size: 12px !important;
    width: auto !important;
    padding: 6px 20px !important;
  }

  .headingtrending {
    font-size: 12px;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .navbar.navbar-expand-lg li a {
    font-size: 12px !important;
  }

  .phonebtn a {
    font-size: 12px !important;
    padding: 12px !important;
  }
}

.phonebtn a {
  font-size: 12px !important;
  padding: 12px !important;
}

.breadcrumb-bg {
  background-image: url(../src/image/contact.jpg);
  background-position: 50%;
  background-size: cover;
  padding: 200px 0 125px;
  position: relative;
  z-index: 1;
}

.breadcrumb-bg:before {
  background: #003580 !important;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6 !important;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

@media (min-width: 768px) {
  .offerbanner {
    height: 500px;
  }
}

.paymentlist li svg {
  height: 30px;
  fill: white;
  width: auto;
}
