/*** 

====================================================================
	Banner One
====================================================================

***/

:root {
  /* Colors */
  --main-color: rgb(73, 151, 211); /* #4997D3 */
  --color-two: rgb(34, 34, 34); /* #222222 */
  --color-three: rgb(246, 246, 246); /* #F6F6F6 */
  --white-color: rgb(255, 255, 255); /* #ffffff */

  /* Fonts */
  --font-family-Poppins: "Poppins", sans-serif;
  --font-family-Oswald: "Oswald", sans-serif;

  /* Font Sizes */
  --font-12: 12px;
  --font-14: 14px;
  --font-16: 16px;
  --font-18: 18px;
  --font-20: 20px;
  --font-24: 24px;
}

.banner-one {
  position: relative;
  text-align: center;
  padding: 114px 0px 70px;
}

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.banner-one.style-two {
  padding: 0px 0px 0px;
}

.banner-one.style-two .slide {
  padding: 170px 0px 70px;
}

.banner-one.style-three {
  padding: 170px 0px 0px;
}

.banner-one.style-three .banner-one_form-box {
  margin-top: var(--margin-top-140);
}

.banner-one.style-three::before {
  position: absolute;
  content: "";
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 73.5px;
  z-index: 1;
  background-color: var(--white-color);
}

.banner-one .owl-dots,
.banner-one .owl-nav {
  display: none;
}

.banner-one video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-one_image-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 6000ms linear;
  -moz-transition: all 6000ms linear;
  -ms-transition: all 6000ms linear;
  -o-transition: all 6000ms linear;
  transition: all 6000ms linear;
}

.banner-one .active .banner-one_image-layer {
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
}

.banner-one_content {
  position: relative;
  z-index: 1;
}

.banner-one_content-inner {
  position: relative;
}

.banner-one_title {
  position: relative;
  font-weight: 500;
  letter-spacing: 0.25em;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--white-color);

  opacity: 1;
  transform: translateY(0px);
}

.slider-version .banner-one_title {
  opacity: 0;
  transform: translateY(100px);
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.slider-version .active .banner-one_title {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transition-delay: 400ms;
  -moz-transition-delay: 400ms;
  -ms-transition-delay: 400ms;
  -o-transition-delay: 400ms;
  transition-delay: 400ms;
}

.banner-one_heading {
  position: relative;
  font-size: 160px;
  font-weight: 700;
  line-height: 1em;
  color: var(--white-color);
  text-transform: uppercase;
  margin-top: var(--margin-top-10);
  opacity: 1;
  transform: translateY(0px);
}

.slider-version .banner-one_heading {
  opacity: 0;
  transform: translateY(100px);
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.slider-version .active .banner-one_heading {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transition-delay: 900ms;
  -moz-transition-delay: 900ms;
  -ms-transition-delay: 900ms;
  -o-transition-delay: 900ms;
  transition-delay: 900ms;
}

.banner-one_text {
  position: relative;
  line-height: 30px;
  opacity: 1;
  transform: translateY(0px);
  color: var(--white-color);
  font-size: var(--font-20);
  margin-top: 10px;
}

.slider-version .banner-one_text {
  opacity: 0;
  transform: translateY(100px);
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.slider-version .active .banner-one_text {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transition-delay: 1400ms;
  -moz-transition-delay: 1400ms;
  -ms-transition-delay: 1400ms;
  -o-transition-delay: 1400ms;
  transition-delay: 1400ms;
}

.banner-one_image-layer {
  background-image: url("../image/1.jpg");
}

.banner-one_form-box {
  position: relative;
  max-width: 1090px;
  margin: 0 auto;
  padding: 40px 45px 30px;
  border-radius: 25px;
  margin-top: 30px;
  background-color: var(--white-color);
  box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.1);
}

.banner-one_form-box .travel-form {
  padding-right: 160px;
}

.banner-one_form-box .travel-form .button-box {
  position: absolute;
  right: 0px;
  top: 0px;
}

/*** 

====================================================================
	Travel Form
====================================================================

***/

.travel-form {
  position: relative;
}

.travel-form .form-group {
  position: relative;
  text-align: left;
  margin-bottom: var(--margin-zero);
}

.travel-form .form-group .form-group_inner {
  position: relative;
  margin-right: var(--margin-right-40);
  padding-right: var(--padding-right-40);
}

.travel-form .form-group .form-group_inner::before {
  position: absolute;
  content: "";
  right: -10px;
  top: 0px;
  width: 1px;
  bottom: 10px;
  opacity: 0.1;
  background-color: var(--black-color);
}

.travel-form .form-group:last-child .form-group_inner::before {
  display: none;
}

.travel-form .form-group:last-child .form-group_inner {
  margin-right: var(--margin-zero);
  padding-right: var(--padding-zero);
}

.travel-form label {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: var(--font-20);
  margin-bottom: var(--margin-bottom-5);
}

/* .travel-form label::before{
	position: absolute;
	content: '\f107';
	right: 0px;
	top: 0px;
	color: var(--main-color);
	font-family: 'Font Awesome 5 Pro';
} */

.travel-form .form-group input[type="text"],
.travel-form .form-group input[type="password"],
.travel-form .form-group input[type="tel"],
.travel-form .form-group input[type="email"],
.travel-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  padding: 0px 0px;
  height: 50px;
  border-radius: 5px;
  font-weight: 400;
  font-size: var(--font-16);
  color: var(--color-fourtynine);
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
  background-color: var(--color-four);
}

.travel-form .form-group textarea::-webkit-input-placeholder,
.travel-form .form-group input::-webkit-input-placeholder {
  opacity: 0.5;
  color: var(--color-two);
}

.travel-form .btn-style-two {
  padding: 20px 40px;
  text-transform: capitalize;
}

.travel-form .form-group input[type="text"]:focus,
.travel-form .form-group input[type="password"]:focus,
.travel-form .form-group input[type="tel"]:focus,
.travel-form .form-group input[type="email"]:focus,
.travel-form .form-group select:focus,
.travel-form .form-group textarea:focus {
}

.theme-btn:hover {
  background-color: #58d6d9;
  transition: all ease ease-in-out;
  color: white;
}

.feature-one {
  position: relative;
  padding: 110px 0px 80px;
  background-color: var(--color-three);
  background-position: center;
  background-image: url(../image/19.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.feature-one.style-two {
  background: none;
}

.feature-one.style-three {
  background: none;
  padding: var(--padding-zero);
}

.feature-one.style-three .inner-container {
  border-radius: 25px;
  padding: 80px 50px;
  background-color: var(--color-three);
}

.feature-block_one {
  position: relative;
  margin-bottom: var(--margin-bottom-30);
}

.feature-block_one-inner {
  position: relative;
  text-align: center;
}

.feature-block_one-icon {
  position: relative;
  line-height: 125px;
  width: 125px;
  height: 125px;
  margin: 0 auto;
  border-radius: 25px;
  color: var(--main-color);
  font-size: var(--font-60);
  font-family: "flaticon_sky-flat";
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-block_one.style-two .feature-block_one-icon {
  background-color: rgba(var(--main-color-rgb), 0.1);
}

.feature-block_one.style-two
  .feature-block_one-inner:hover
  .feature-block_one-icon {
  color: var(--white-color);
  background-color: var(--main-color);
}

.feature-block_one.style-two .feature-block_one-text {
  padding: var(--padding-zero);
}

.feature-block_one-inner:hover .feature-block_one-icon {
  -webkit-transform: scale(-1) rotate(180deg);
  -moz-transform: scale(-1) rotate(180deg);
  -ms-transform: scale(-1) rotate(180deg);
  -o-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
  background-color: var(--white-color);
  box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.05);
}

.feature-block_one-title {
  position: relative;
  color: black !important;
  font-size: 24px;
  font-weight: 500;
  margin-top: var(--margin-top-35);
  font-family: var(--font-family-Poppins);
}

.feature-block_one-text {
  position: relative;
  line-height: 26px;
  padding: 0px 20px;
  color: var(--color-two);
  font-size: var(--font-16);
  margin-top: var(--margin-top-15);
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.css-3kd8s5-control {
  border: 0 !important;
  font-size: 16px !important;
}

/* Card */

.location-block_one {
  position: relative;
  margin-bottom: var(--margin-bottom-30);
}
.location-block_one-inner {
  position: relative;
  border-radius: 25px;
  padding: 15px 15px 30px;
  box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.05);
}
.location-block_one-image {
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  background-color: var(--main-color);
}

.location-block_one-image img {
  position: relative;
  height: 250px;
  width: 100%;
  display: block;
  transition: all 500ms ease;
  object-fit: cover;
}
.location-block_one-content {
  position: relative;
  padding-top: var(--padding-top-20);
}
.location-block_one-heading {
  position: relative;
  font-family: var(--font-family-Poppins);
}
.location-block_one-location {
  position: relative;
  font-size: var(--font-16);
  display: inline-block;
  margin-top: var(--margin-top-10);
  padding-left: var(--padding-left-30);
}

.location-block_one-arrow {
  position: absolute;
  right: 0px;
  top: 22px;
  width: 60px;
  height: 60px;
  opacity: 0;
  transform: scale(0, 1);
  line-height: 60px;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  color: var(--white-color);
  font-size: var(--font-20);
  font-family: "flaticon_sky-flat";
  background-color: var(--main-color);
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.location-block_one-inner:hover {
  box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.25);
}
.location-block_one-inner:hover .location-block_one-image img {
  opacity: 0.6;
  transform: scale(1.08, 1.08) rotate(1deg);
}

.location-block_one-inner:hover .location-block_one-arrow {
  opacity: 1;
  transform: scale(1, 1);
}

@media only screen and (max-width: 767px) {
  .banner-one_title {
    font-size: var(--font-16);
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  .banner-one_heading {
    font-size: 60px;
  }
  .banner-one_text {
    line-height: 30px;
    font-size: var(--font-18);
  }
  .banner-one_form-box {
    padding: 20px 20px;
    border-radius: 15px;
    background-color: transparent !important;
  }
  .banner-one_form-box .travel-form {
    padding-right: var(--padding-zero);
    background: white !important;
    padding: 10px 20px;
    border-radius: 16px;
  }
  .travel-form .form-group {
    margin-bottom: var(--margin-bottom-20);
  }
  .travel-form .form-group .form-group_inner {
    margin-right: var(--margin-zero);
    padding-right: var(--padding-zero);
  }
  .banner-one_form-box .travel-form .button-box {
    position: relative;
  }
  .css-3kd8s5-control {
    border: 2px solid gray !important;
    font-size: 16px !important;
  }
  .feature-one {
    padding: 60px 0px 80px;
  }
}
